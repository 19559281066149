<template>
  <div class="rightLink">
    <div class="bottomDiv">
      <div class="title">物联网卡管理</div>
      <div class="tableDiv">
        <el-table
          :data="tableData"
          border
          v-loading="loading"
          height="480px"
          style="width: 90%; margin: 20px auto; overflow-y: auto; margin-top: 20px;"
        >
          <el-table-column label="序号" type="index" align="center" width="60">
            <template #default="{ $index }">
              {{ (currentPage - 1) * pageSize + $index + 1 }}
            </template>
          </el-table-column>
          <el-table-column label="套餐id" prop="taono" />
          <el-table-column label="运营商" prop="operators" />
          <el-table-column label="续费周期" prop="tctype" />
          <el-table-column label="流量" prop="cj_product" />
          <el-table-column label="价格(元)" prop="price"/>
          <el-table-column label="支付金额(元)" prop="pay_money" />
          <el-table-column
            label="操作"
            fixed="right"
            align="center"
          >
            <template #default="scope">
              <div class="btntext">
                <el-link
                  target="_blank"
                  type="warning"
                  @click="handleEdit(scope.$index, scope.row)"
                  >编辑
                </el-link>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination">
          <el-pagination
            :current-page="FormData.page"
            :page-size="pageSize"
            :small="small"
            layout="total, slot, prev, pager, next, jumper"
            :total="total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          >
            <template #default>
              <span class="el-pagination__total">{{ Math.ceil(total / pageSize) }} 页 </span>
            </template>
          </el-pagination>
        </div>
        <div v-dialogdrag>
          <el-dialog
            v-model="Dialog.editLevy"
            :title="DiaLogFlagTitle"
            width="620px"
            :before-close="closeDialog"
            class="dialog"
          >
            <div class="formDiv1">
              <el-form
                ref="FormRules"
                :rules="rules"
                :model="FormData"
                label-position="right"
                label-width="100px"
              >
                <el-row :gutter="20">
                  <el-col :span="1"></el-col>
                  <el-col :span="10">
                    <el-form-item label="套餐id:">
                      <el-input
                        v-model="Dialog.DialogData.taono"
                        :disabled="isEdit ? true : false"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <!-- <el-col :span="1"></el-col> -->
                  <el-col :span="10">
                    <el-form-item label="运营商:">
                      <el-input
                        v-model="Dialog.DialogData.operators"
                        :disabled="isEdit ? true : false"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="20">
                  <el-col :span="1"></el-col>
                  <el-col :span="10">
                    <el-form-item label="续费周期:">
                      <el-input
                        v-model="Dialog.DialogData.tctype"
                        :disabled="isEdit ? true : false"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <!-- <el-col :span="1"></el-col> -->
                  <el-col :span="10">
                    <el-form-item label="流量:">
                      <el-input
                        v-model="Dialog.DialogData.cj_product"
                        :disabled="isEdit ? true : false"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="20">
                  <el-col :span="1"></el-col>
                  <el-col :span="10">
                    <el-form-item label="价格:">
                      <el-input
                        v-model="Dialog.DialogData.price"
                        :disabled="isEdit ? true : false"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <!-- <el-col :span="1"></el-col> -->
                  <el-col :span="10">
                    <el-form-item label="支付金额:">
                      <el-input
                        v-model="Dialog.DialogData.pay_money"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form>
            </div>
            <template #footer>
              <span class="dialog-footer">
                <el-button class="Btn" @click="closeDialog">取消</el-button>
                <el-button class="Btn" type="primary" @click="submitDialog"
                  >提交</el-button
                >
              </span>
            </template>
          </el-dialog>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import { onMounted } from "vue";
import { ref } from "vue";
import { onMounted, reactive, toRefs } from "vue-demi";
import {
  sel_meal,
  manage_device,
  operator_data,
  sel_device,
  upd_meal
} from "@/request/api";
import { ElMessageBox, ElMessage } from "element-plus";
import { regionData } from "element-china-area-data";
export default {
  name: "card_manage",
  setup() {
    const data = reactive({
        //表单数据
      isEdit: false,
        FormData: {
        card_no:"",
        page: 1,
        },
      tableData: [],
      tableData1: [],
      device_list: [],
      charg_list: [],
      chargLists: [],
      chargList2: [],
      chargList4: [],
      //   弹框
      Dialog: {
        editLevy: false,
        DialogData: {},
        DiaLogFlagTitle:"",
      },
      price_id: "",
      loading: false,

      //   分页
      currentPage: 1,
      pageSize: 20,
      total: 0,
      checked: 0,
      small: true,

      options: regionData,
      selectedOptions: "",

      temps: [],
      isadmin: false,
      users: [],
      FormData: {},

    });
    const searchBtn = () => {
      data.FormData.page = 1;
      getList();
      };
      //禁用
    const isEdit = ref(false);
    
    const delBtn = (index) => {
      // console.log(index,'index');
      // console.log(data.device_list.length,'row');
      if (data.device_list.length <= 1) {
        //如果只有一个框则不可以删除
        return false;
      }
      data.device_list.forEach((it, ites) => {
        if (it == index) {
          data.device_list.splice(ites, 1); //删除数组中对应的数据也就是将这个位置的框删除
        }
      });
      // data.device_list.splice(index, 1) //删除数组中对应的数据也就是将这个位置的框删除
    };
      const handleEdit = (index, row) => {
      console.log('打开编辑弹框11111');
      data.Dialog.editLevy = true;
      data.Dialog.DialogData = JSON.parse(JSON.stringify(row));
      data.DiaLogFlagTitle = "编辑金额信息";
      data.isEdit = true;
      console.log(row,'--=');
    };
    // let formdata = new FormData()

    const addDevice = () => {
      if (data.device_list.length <= 20) {
        data.device_list.push("");
      }
    };

    const submitDialog = (taono,pay_money) => {
        //   submitData();
    // formdata.append('taono',data.Dialog.DialogData.taono)
    // formdata.append('operators',data.Dialog.DialogData.password)
    // formdata.append('tctype',data.Dialog.DialogData.tctype)
    // formdata.append('cj_product', data.Dialog.DialogData.cj_product)
    // formdata.append('price', data.Dialog.DialogData.price)
    // formdata.append('pay_money', data.Dialog.DialogData.pay_money)
      const dataa = {
        taono: data.Dialog.DialogData.taono,
        pay_money: data.Dialog.DialogData.pay_money
      };
      console.log('1111',dataa);
      upd_meal(dataa).then((res) => {
        console.log(res);
        if (res) {
          closeDialog();
          if (res.code == 200) {
            ElMessage({
              showClose: true,
              message: "编辑成功",
              type: "success",
            });
              getList();
          } else {
            ElMessage.error(res.msg);
          }
        } else {
          ElMessage.error("编辑失败");
        }
      });
    };
    const closeDialog = () => {
      data.Dialog.editLevy = false;
    };
    const changesmb = (dd) => {
      console.log(dd);
      data.price_id = dd.id;
    };

    const getList = () => {
      const dataa = data.FormData;
      console.log(dataa,'dataa');
      data.loading = true;
      sel_meal(dataa).then((res) => {
        if (res) {
          data.loading = false;
          if (res.code == 200) {
            data.tableData = res.info;
            data.total = res.info.length;
          } else {
            ElMessage.error(res.msg);
          }
        } else {
          ElMessage.error("数据获取失败");
        }
      });
    };
    const chargs = (e) => {
      console.log(e, "vallllll");
      console.log(data.charg_list, "charg_listcharg_list");
      // console.log(row,'aaaaaaaaa');
    };

    // const getchargList = () => {
    //   data.loading = true;
    //   const dataa = {
    //     username: localStorage.getItem("username"),
    //   };
    //   sel_device(dataa).then((res) => {
    //     if (res) {
    //       data.loading = false;
    //       if (res.code == 200) {
    //         console.log('res.data', res.data)
    //         res.data.forEach((a) => {
    //           // console.log(a,'aaaaaa');
    //           if (a.station_name == null) {
    //             if (a.type_id == 2) {
    //               data.chargList2.push(a);
    //               console.log(
    //                 JSON.parse(JSON.stringify(data.chargList2)),
    //                 "this.chargListthis.chargList222"
    //               );
    //             } else if (a.type_id == 3 || a.type_id == 4) { // 直流桩或交流桩
    //               data.chargList4.push(a);
    //               console.log(
    //                 JSON.parse(JSON.stringify(data.chargList4)),
    //                 "this.chargListthis.chargList4444"
    //               );
    //             }
    //           }
    //         });
    //       } else {
    //         ElMessage.error(res.msg);
    //       }
    //     } else {
    //       ElMessage.error("数据获取失败");
    //     }
    //   });
    //   console.log(data.options, "options");
    // };
    const getdevList = () => {
      data.loading = true;
      manage_device().then((res) => {
        console.log(res, "manage_device");
        if (res) {
          data.loading = false;
          if (res.code == 200) {
            data.tableData1 = res.data;
          } else {
            ElMessage.error(res.msg);
          }
        } else {
          ElMessage.error("数据获取失败");
        }
      });
      console.log(data.options, "options");
    };

    // const handleChange = (value) => {
    //   console.log(value, "value");
    //   // console.log(myOptions,'myOptions')
    //   const checkedNodes = this.$refs.myOptions.getCheckedNodes()[0].pathLabels;
    //   console.log(checkedNodes);
    // };

    // const handleChange1 = () => {
    //   // console.log(e,'value')
    //   const checkedNodes = this.$refs.myOptions1.getCheckedNodes();
    //   console.log(checkedNodes);
    // };
    const handleSizeChange = (val) => {
      data.pageSize = val;
    };
    const handleCurrentChange = (val) => {
      data.FormData.page = val;
      getList();
      //
    };

    // const initProvinceList = () => {
    //   // 初始化省份列表数据源
    //   regionData.forEach((tab) => {
    //     data.provinceList.push(tab);
    //   });
    // };
    // const handleProvinceChange = (val) => {
    //   console.log(val, "009-");
    //   data.cityList = [];
    //   data.countyList = [];
    //   data.province = val;
    //   data.FormData.city = "";
    //   data.FormData.county = "";
    //   console.log(data.province, "]]");
    //   initCityList();
    // };

    // const initCityList = () => {
    //   console.log(data.province, "--2");
    //   // 初始化城市列表数据源
    //   data.provinceList.forEach((tab1) => {
    //     // console.log(tab1,'--==tab1');
    //     if (tab1.label == data.province) {
    //       data.cityList.push(tab1.children);
    //     }
    //   });
    // };

    // const handleCityChange = (value1) => {
    //   data.countyList = [];
    //   data.city = value1;
    //   data.FormData.county = "";
    //   console.log(data.city, "]]999");
    //   initCountyList();
    // };

    // const initCountyList = () => {
    //   // 初始化县/区列表数据源
    //   console.log(data.city, "--299");
    //   // 初始化城市列表数据源
    //   data.cityList[0].forEach((tab2) => {
    //     console.log(tab2, "tab2");
    //     // tab2.forEach((tab3)=>{
    //     //   console.log(tab3,'tab3');
    //     //    if(tab3.label==this.selectedCity){
    //     //  this.countyList.push(tab3.children)
    //     // }
    //     // })
    //     if (tab2.label == data.city) {
    //       data.countyList.push(tab2.children);
    //     }
    //   });
    // };

    const getUsername = () => {
      const username1 = localStorage.getItem("username");
      const users1 = [];
      operator_data().then((res) => {
        console.log(res, "sssssss");
        data.users = res;
        res.forEach((a) => {
          users1.push(a.username);
        });
        if (users1.indexOf(username1) == -1) {
          data.isadmin = true;
        } else {
          data.isadmin = false;
          data.FormData.username = username1;
        }
      });
    };

    onMounted(() => {
      console.log(data);
      getList();
    //   initProvinceList();
      getUsername();
    });
    return {
      ...toRefs(data),
      ref,
      searchBtn,
      delBtn,
      getUsername,
      handleEdit,
      closeDialog,
      submitDialog,
      changesmb,
    //   submitData,
      getList,
      getdevList,
      addDevice,
    //   handleChange,
    //   handleChange1,
      handleSizeChange,
      handleCurrentChange,
    //   initProvinceList,
    //   handleProvinceChange,
    //   handleCityChange,
    //   initCityList,
    //   initCountyList,
    //   getchargList,
      chargs,
    };
  },
};
</script>
<style scoped>
.formDiv1 {
  margin-top: 10px;
  height: 210px;
}
.el-row {
  height: 45px;
}
.el-select /deep/ .el-input__wrapper {
  width: 500px !important;
  --el-input-focus-border-color: #7bd8d3;
}
.btn {
  color: #fff;
  background-color: #03beb0;
  border-color: #03beb0;
  width: 55px;
  height: 28px;
  line-height: 28px;
  margin-left: -100px;
}
.Btns {
  color: #fff;
  background-color: #03beb0;
  border-color: #03beb0;
  width: 175px;
  margin-left: 40px;
  margin-top: 20px;
}
.btn1 {
  margin-left: 340px;
}
.btn:hover {
  background-color: #05d5c7;
  border-color: #05d5c7;
}

.btn:focus {
  background-color: #03beb0;
  border-color: #03beb0;
}

.BtnSearch1 {
  width: 100px !important;
  margin: 4px 4px;
}

.pagination {
  display: flex;
  justify-content: space-between;
  padding: 0 67px;
  margin-top: -10px;
}

.bottomDiv {
  height: 100%;
}
.input-with-select {
  width: 10vw;
}
.el-link {
  margin: 0 3px;
}
</style>
